import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import './BuscaAvancada.css';
import FiltersCategory from '../../components/FilterCategory/FilterCategory';
import FilterAvaliacao from '../../components/FilterAvaliacao/FilterAvaliacao';
import { fetchClippings, fetchClippingById, fetchClippingIds } from '../../services/api/fetchClipping';
import { fetchGroupers } from '../../services/api/fetchGrouper';
import { fetchSources } from '../../services/api/fetchSources';
import { fetchCities } from '../../services/api/fetchLocation';
import { fetchStates } from '../../services/api/fetchLocation';
import { fetchCategories } from '../../services/api/fetchCategories';
import { fetchUser } from '../../services/api/fetchUser';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactLoading from 'react-loading';
import AsyncSelect from 'react-select/async';
import Card from '../../components/Card/Card';
import defaultImage from '../../assets/icone-card-tv.jpg';
import tvImage from '../../assets/icone-card-tv.jpg';
import radioImage from '../../assets/icone-crad-radio.jpg';
import printImage from '../../assets/icone-card-impresso.jpg';
import siteImage from '../../assets/icone-card-site.jpg';
import impressoImage from '../../assets/icone-card-impresso.jpg';
import NewsPDFExporter from '../../services/NewsPDFExporter';
import ExportModal from '../../components/ModalExport/ModalExport';
import Avaliacao from '../../components/Avaliacao/Avaliacao';
import { exportClippings } from '../../services/api/fetchClipping';
import ActionsModal from '../../components/ActionsModal/ActionsModal';
import { deleteEvaluation } from '../../services/api/fetchClipping';

export function BuscaAvancada({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [states, setStates] = useState([]);
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const { clientId } = useParams();
  const [author, setAuthor] = useState("");
  const [showFilterCategory, setShowFilterCategory] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasEvaluation, setHasEvaluation] = useState({ value: '', label: 'Todos' });
  const [vehicleSearchTerm, setVehicleSearchTerm] = useState("");
  const [lastSearchLength, setLastSearchLength] = useState(0);
  const resultsContainerRef = useRef(null);
  const [clippings, setClippings] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [nextLink, setNextLink] = useState('');
  const [userProfile, setUserProfile] = useState(null);
  const pdfExporterRef = useRef();
  const [selectedCards, setSelectedCards] = useState([]);
  const [showAvaliacao, setShowAvaliacao] = useState(false);
  const [avaliadoIds, setAvaliadoIds] = useState([]);
  const [exportSuccessMessage, setExportSuccessMessage] = useState('');
  const [exportErrorMessage, setExportErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [pdfExportSuccessMessage, setPdfExportSuccessMessage] = useState('');
  const [pdfExportErrorMessage, setPdfExportErrorMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [showExportModal, setShowExportModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Seleção manual');
  const [hasSearched, setHasSearched] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClippingId, setSelectedClippingId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);


  useEffect(() => {
    // Limpar filtros do sessionStorage ao recarregar a página
    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem('buscaAvancadaFilters');
    });

    return () => {
      window.removeEventListener('beforeunload', () => {
        sessionStorage.removeItem('buscaAvancadaFilters');
      });
    };
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (vehicleSearchTerm.length > 2) {
        try {
          const results = await fetchSources(vehicleSearchTerm);
          setSources(results);
        } catch (error) {
          console.error('Erro ao buscar veículos:', error);
        }
      }
    }, 300); // Espera de 300ms após o último input
  
    return () => clearTimeout(delayDebounceFn);
  }, [vehicleSearchTerm, clientId]);

  useEffect(() => {
    // Carregar filtros do sessionStorage
    const savedFilters = JSON.parse(sessionStorage.getItem('buscaAvancadaFilters'));
    if (savedFilters) {
      setSearchTerm(savedFilters.searchTerm);
      setSourceType(savedFilters.sourceType);
      setSelectedCity(savedFilters.selectedCity);
      setSelectedState(savedFilters.selectedState);
      setSelectedSource(savedFilters.selectedSource);
      setSelectedCategory(savedFilters.selectedCategory);
      setStartDate(savedFilters.startDate ? new Date(savedFilters.startDate) : null);
      setEndDate(savedFilters.endDate ? new Date(savedFilters.endDate) : null);
      setAuthor(savedFilters.author);
      setSelectedFilters(savedFilters.selectedFilters || []);
    } else {
      const currentDate = new Date();
      const pastDate = new Date();
      pastDate.setDate(currentDate.getDate() - 7);
      setStartDate(pastDate);
      setEndDate(currentDate);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedStates = sessionStorage.getItem('states');
        const storedCities = sessionStorage.getItem('cities');
  
        if (storedStates && storedCities) {
          setStates(JSON.parse(storedStates));
          setCities(JSON.parse(storedCities));
        } else {
          const [states, cities] = await Promise.all([
            fetchStates(),
            fetchCities()
          ]);
          setStates(states);
          setCities(cities);
          sessionStorage.setItem('states', JSON.stringify(states));
          sessionStorage.setItem('cities', JSON.stringify(cities));
        }
  
        const [categories, sources] = await Promise.all([
          fetchCategories(clientId),
          fetchSources()
        ]);
        setCategories(categories);
        setSources(sources);
      } catch (error) {
        console.error('Erro ao buscar estados, cidades, veículos e categorias:', error);
      }
    };
  
    fetchData();
  }, [clientId, selectedFilters]);

  useEffect(() => {
    if (selectedState) {
      const filtered = cities.filter(city => city.state === selectedState.value);
      setFilteredCities(filtered);
    } else {
      setFilteredCities(cities);
    }
  }, [selectedState, cities]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await fetchUser();
        setUserProfile(user.profile);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleFilterChange = (category) => {
    if (category === 'todos') {
      setSourceType('');
    } else {
      setSourceType(category);
    }
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedCity(null);
  };

  const handleSourceChange = (selectedOption) => {
    setSelectedSource(selectedOption);
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length >= 3) {
      try {
        const results = await fetchSources(inputValue);
        const options = results.map(source => ({ value: source.id, label: source.name }));
        callback(options);
      } catch (error) {
        console.error('Erro ao buscar veículos:', error);
      }
    } else {
      callback([]);
    }
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      setVehicleSearchTerm(newValue);
    } else if (action === 'menu-close') {
      setSources([]);
    }
  };
  
  const handleChange = (selectedOption) => {
    setSelectedSource(selectedOption);
    if (!selectedOption) {
      setSources([]);
    }
  };
  
  const handleBlur = () => {
    setSources([]);
  };
  

  const handleCategoryChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'select_all')) {
      setSelectedCategory(categoryOptions.filter(option => option.value !== 'select_all'));
    } else {
      setSelectedCategory(selectedOptions);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    // Reset end date if it's before start date
    if (endDate && date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    // Reset start date if it's after end date
    if (date < startDate) {
      setStartDate(null);
    }
  };

  const handleButtonClickSearch = () => {
    setSelectedOption("Seleção manual");
    setCurrentPage(1); 
    handleSearch(1);
  };

  const handleSearch = async (page = 1) => {
    const formatDate = (date) => {
      if (!date) return "";
      return date.toISOString().split('T')[0];
    };

    // Validate start and end date
    if (!startDate || !endDate) {
      setErrorMessage("Por favor, selecione a data inicial e final.");
      setTimeout(() => {
        setErrorMessage(false);
      }, 4000);
      return;
    }

    const diffInDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
    if (diffInDays > 60) {
      setErrorMessage("A diferença entre a data inicial e final não pode ser maior que 60 dias.");
      setTimeout(() => {
        setErrorMessage(false);
      }, 4000);
      return;
    }

    const searchData = {
      searchTerm,
      sourceType,
      city: selectedCity?.value,
      state: selectedState?.value,
      source: selectedSource?.value,
      categories: selectedCategory?.map(category => category.value).join(','),
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      author,
      hasEvaluation: hasEvaluation?.value,
      groupers: selectedFilters.map(filter => ({
        id: filter.id,
        value: filter.value
      })),
      page,
      itemsPerPage: 50
    };

    console.log('Dados enviados para a API:', searchData);

    // Salvar filtros no sessionStorage
    sessionStorage.setItem('buscaAvancadaFilters', JSON.stringify({
      searchTerm,
      sourceType,
      selectedCity,
      selectedState,
      selectedSource,
      selectedCategory,
      startDate,
      endDate,
      author,
      selectedFilters
    }));

    setIsLoading(true);
    try {
      const data = await fetchClippings(clientId, searchTerm, sourceType, selectedCity?.value, selectedState?.value, selectedSource?.value, searchData.categories, formatDate(startDate), formatDate(endDate), author, selectedFilters.map(filter => ({
        id: filter.id,
        value: filter.value
      })), hasEvaluation?.value, page, 50);

      const totalItems = data.count ? parseInt(data.count, 10) : 0;
      const totalPages = Math.ceil(totalItems / 50);
      const nextLink = data.next;

      setClippings(data.results);
      setFilteredData(data.results);
      setTotalItems(totalItems);
      setTotalPages(totalPages);
      setNextLink(nextLink);
      setCurrentPage(page);
      setHasSearched(true);

      if (totalItems === 0) {
        setErrorMessage('Nenhuma notícia encontrada para o período selecionado.');

        setTimeout(() => {
            setErrorMessage('');
        }, 5000);

    } else {
        setErrorMessage('');
    }

    } catch (error) {
      console.error('Erro ao buscar resultados:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (hasSearched && resultsContainerRef.current) {
      resultsContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [clippings, hasSearched]);

  const handleEvaluationChange = (selectedOption) => {
    setSelectedEvaluation(selectedOption);
  };

  const handleShowFilterCategory = () => {
    setShowFilterCategory(true);
  };

  const handleCloseFilterCategory = () => {
    setShowFilterCategory(false);
  };

  const handleAddFilter = async (filters) => {
    const groupersData = await fetchGroupers(clientId);
    const formattedFilters = filters.map(filter => {
      const grouper = groupersData.find(g => g.name === filter.grouper_detail.name);
      return {
        id: grouper.id,
        name: filter.grouper_detail.name,
        value: filter.value
      };
    });
    setSelectedFilters(formattedFilters);
    handleCloseFilterCategory();
  };

  const handleRemoveFilter = (index) => {
    setSelectedFilters(selectedFilters.filter((_, i) => i !== index));
  };

  const handleClearFilters = () => {
    setSelectedFilters([]);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '10px',
      fontSize: '12px',
      borderColor: state.isFocused ? '#ccc' : '#ccc',
      borderRadius: '4px',
      backgroundColor: 'white',
      color: '#333',
      outline: 'none',
      boxShadow: state.isFocused ? '0 0 0 1px #ccc' : 'none',
      transition: 'border-color 0.3s, box-shadow 0.3s',
      '&:hover': {
        borderColor: '#ccc',
      },
      maxHeight: '150px', 
      overflowY: 'auto', 
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#eee' : '#fff',
      color: '#333',
      padding: '10px',
      '&:active': {
        backgroundColor: '#eee',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  const uniqueOptions = (options) => {
    return options.reduce((acc, current) => {
      const x = acc.find(item => item.label === current.label);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  };

  const handleFetchClippingIds = async () => {
    const formatDate = (date) => {
      if (!date) return "";
      return date.toISOString().split('T')[0];
    };

    setIsLoading(true);
  
    try {
      const idsResponse = await fetchClippingIds(
        clientId,
        searchTerm,
        sourceType,
        selectedCity?.value,
        selectedState?.value,
        selectedSource?.value,
        selectedCategory?.map(category => category.value).join(','),
        formatDate(startDate),
        formatDate(endDate),
        author,
        selectedFilters.map(filter => ({
          id: filter.id,
          value: filter.value,
        })),
        hasEvaluation?.value,
      );

      const newIds = idsResponse.map((item) => item.id);
      const updatedSelectedCards = Array.from(new Set([...selectedCards, ...newIds]));

      setSelectedCards(updatedSelectedCards);

      setSuccessMessage(`Todas as ${newIds.length} notícias foram selecionadas com sucesso!`);
      setTimeout(() => setSuccessMessage(''), 5000); 
  
      console.log('IDs retornados:', idsResponse);
    } catch (error) {
      console.error('Erro ao buscar IDs dos clippings:', error);

      setErrorMessage(`Erro ao selecionas as notícias. Por favor, tente novamente!`);
      setTimeout(() => setErrorMessage(''), 5000); 
    } finally {
      setIsLoading(false);
    }
  };

  const stateOptions = uniqueOptions(states.map((state) => ({ value: state.id, label: state.name })));
  const cityOptions = uniqueOptions(filteredCities.map((city) => ({ value: city.id, label: city.name })));
  const sourceOptions = uniqueOptions(sources.map((source) => ({ value: source.id, label: source.name })));
  const categoryOptions = [
    { value: 'select_all', label: 'Selecionar Todas' },
    ...uniqueOptions(categories.map((category) => ({ value: category.id, label: category.name })))
  ];

  const handleOptionChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);

    switch (option) {
      case 'Seleção manual':
        break;
      case 'Selecionar todos':
        handleFetchClippingIds();
        break;
      default:
        setSelectedCards([]);
        break;
    }
  };

  const handleOpenAvaliacao = () => {
    if (selectedCards.length > 0) {
      setShowAvaliacao(true);
      console.log("IDs dos cards selecionados para avaliação:", selectedCards);
    }
  };

  const handleCloseAvaliacao = () => {
    setShowAvaliacao(false);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
  };

  const handleErrorMessage = (message) => {
    setErrorMessage(message);
  };

  const handleAvaliar = (filterData) => {
    setAvaliadoIds((prev) => [...prev, ...filterData.clippings]);
    console.log("IDs dos cards avaliados:", filterData.clippings);
    setShowAvaliacao(false);
    handleSuccessMessage('Avaliação realizada com sucesso!');
    setTimeout(() => setSuccessMessage(''), 5000);
  };

  const handleCardSelectChange = (id) => {
    setSelectedCards((prevSelected) => {
      const updatedSelected = prevSelected.includes(id)
        ? prevSelected.filter(cardId => cardId !== id)
        : [...prevSelected, id];
      console.log("IDs dos cards selecionados:", updatedSelected);
      return updatedSelected;
    });
  };

  const handleExportClippings = async (email) => {
    setIsLoading(true);
    try {

      const response = await exportClippings(selectedCards, email);
      
      if (response.success && response.file_url) {
        setExportSuccessMessage('Relatório exportado com sucesso!');
        setExportErrorMessage('');
  
        const link = document.createElement('a');
        link.href = response.file_url;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } else if (response.success && response.message) {
        setExportSuccessMessage('Relatório sendo gerado! Será enviado para o e-mail em instantes.');
        setExportErrorMessage('');
      } else {
        setExportErrorMessage('Erro ao exportar os clippings.');
        setExportSuccessMessage('');
      }
      
      setTimeout(() => {
        setExportSuccessMessage('');
        setExportErrorMessage('');
      }, 5000);
    } catch (error) {
      console.error('Erro ao exportar os clippings:', error);
      setExportErrorMessage('Erro ao exportar os clippings.');
      setExportSuccessMessage('');
      setTimeout(() => setExportErrorMessage(''), 5000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportPDF = async (title, subtitle, sortOption, includeTitleAndSubtitle) => {

    if (selectedCards.length > 500) {
      setErrorMessage('Não é possível gerar o PDF com mais de 500 notícias. Por favor, selecione no máximo 500.');
      setTimeout(() => setErrorMessage(''), 5000);
      return;
    }

    setIsLoading(true);
  
    try {
      const newsItemsPromises = selectedCards.map((clippingId) => fetchClippingById(clippingId));
      const newsItems = await Promise.all(newsItemsPromises);
  
      if (pdfExporterRef.current) {
        await pdfExporterRef.current.exportPDF(newsItems, title, subtitle, sortOption, includeTitleAndSubtitle);
        setPdfExportSuccessMessage('Exportação realizada com sucesso!');
        setTimeout(() => setPdfExportSuccessMessage(''), 5000);
      }
    } catch (error) {
      console.error('Erro ao exportar os clippings:', error);
      setPdfExportErrorMessage('Erro ao exportar os clippings.');
      setTimeout(() => setPdfExportErrorMessage(''), 5000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = (action) => {
    if (selectedCards.length === 0) {
      setWarningMessage(`Por favor, selecione ao menos um card para ${action}.`);
      setTimeout(() => setWarningMessage(''), 5000);
    } else {
      if (action === 'exportar') {
        setShowExportModal(true);
      } else if (action === 'avaliar') {
        setIsActionsMenuOpen(false);
        handleOpenAvaliacao();
      }
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === 'modal-overlay-resultado-busca') {
      handleCloseAvaliacao();
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const renderEllipsis = (key) => (
      <span key={key} className="pagination-ellipsis">...</span>
    );

    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3);
        pages.push(renderEllipsis('ellipsis-right'));
        pages.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1);
        pages.push(renderEllipsis('ellipsis-left'));
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push(renderEllipsis('ellipsis-left'));
        pages.push(currentPage - 1, currentPage, currentPage + 1);
        pages.push(renderEllipsis('ellipsis-right'));
        pages.push(totalPages);
      }
    }

    return pages.map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          onClick={() => handleSearch(page)}
        >
          {page}
        </button>
      ) : (
        page
      )
    );
  };

  const getImageUrl = (type) => {
    if (type === 'tv') {
      return tvImage;
    } else if (type === 'radio') {
      return radioImage;
    } else if (type === 'print') {
      return printImage;
    } else if (type === 'site') {
      return siteImage;
    } else if (type === 'impresso') {
      return impressoImage;
    } else {
      return defaultImage;
    }
  };

  const handleCardClick = (clippingId, index) => {
    if (Array.isArray(filteredData)) {
      const clippingIndex = filteredData.findIndex(clipping => clipping.id === clippingId);
      if (clippingIndex !== -1) {
        setSelectedClippingId(clippingId);
        setCurrentIndex(clippingIndex);
        setIsModalOpen(true);
      } else {
        console.error("Clipping not found in filteredData");
      }
    } else {
      console.error("filteredData is not an array");
    }
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedClippingId(null);
    setCurrentIndex(null);
  };
  
  const handleNext = () => {
    if (currentIndex < filteredData.length - 1) {
      const nextIndex = currentIndex + 1;
      setSelectedClippingId(filteredData[nextIndex].id);
      setCurrentIndex(nextIndex);
    }
  };
  
  const handlePrevious = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setSelectedClippingId(filteredData[prevIndex].id);
      setCurrentIndex(prevIndex);
    }
  };

  const handleClearSelectedCards = () => {
    setSelectedCards([]);
    setSuccessMessage('Seleção limpa com sucesso!');
    setTimeout(() => setSuccessMessage(''), 5000);
  };

  const handleOpenActionsMenu = () => {
    setIsActionsMenuOpen(!isActionsMenuOpen);
  };
  
  const handleDeleteEvaluations = async () => {
    if (selectedCards.length === 0) {
      setErrorMessage('Por favor, selecione ao menos um card para excluir.');
      setTimeout(() => setErrorMessage(''), 5000);
      return;
    }

    setIsLoading(true);
    try {
      await deleteEvaluation(selectedCards);
      setSuccessMessage('Avaliações excluídas com sucesso.');
      setTimeout(() => setSuccessMessage(''), 5000);
      setIsActionsMenuOpen(false);
    } catch (error) {
      setErrorMessage('Erro ao excluir avaliações. Tente novamente.');
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='Busca-avancada'>
      {isLoading && (
        <div className={isLoading ? 'loading-overlay active' : 'loading-overlay'}>
          <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
        </div>
      )}
      <div className="filtercategoryPesquisa">
        <FiltersCategory onFilterChange={handleFilterChange} className="filtercategory" showAssuntos={false}/>
      </div>

      <div className='filters'>
        <div className="summary_pesquisar">
          <div className="date-picker-filters-pesquisar-text">
            <div className="date-picker-label-filters-pesquisar">Palavras a localizar</div>
            <input
              type="text"
              className="custom-select-pesquisar-text"
              placeholder="Digite um texto"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="filter-container-pesquisar">
          <div className="filter-label-pesquisar">Estado</div>
          <Select
            className="filter-select-pesquisar"
            value={selectedState}
            onChange={handleStateChange}
            options={stateOptions}
            styles={customStyles}
            placeholder="Todos"
          />
        </div>

        <div className="filter-container-pesquisar">
          <div className="filter-label-pesquisar">Cidade</div>
          <Select
            className="filter-select-pesquisar"
            value={selectedCity}
            onChange={handleCityChange}
            options={cityOptions}
            styles={customStyles}
            placeholder="Todos"
            isDisabled={!selectedState}
          />
        </div>
      </div>

      <div className='filters2'>
        <div className="summary_pesquisar">
          <div className="date-picker-filters-pesquisar-text">
            <div className="date-picker-label-filters-pesquisar">Autor / Apresentador</div>
            <input
              type="text"
              className="custom-select-pesquisar-text"
              placeholder="Digite Autor/Apresentador"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </div>
        </div>

        <div className="filter-container-pesquisar2">
          <div className="filter-label-pesquisar2">Avaliação</div>
          <Select
            className="filter-select-pesquisar2"
            value={hasEvaluation}
            onChange={(selectedOption) => setHasEvaluation(selectedOption)}
            options={[
              { value: '', label: 'Todos' },
              { value: 'true', label: 'Avaliado' },
              { value: 'false', label: 'Não Avaliado' }
            ]}
            styles={customStyles}
            placeholder="Selecione"
          />
        </div>
        
        <div className="filter-container-pesquisar2">
          <div className="filter-label-pesquisar2">Categoria</div>
          <Select
            className="filter-select-pesquisar2"
            value={selectedCategory}
            onChange={handleCategoryChange}
            options={categoryOptions}
            styles={customStyles}
            placeholder="Todos"
            isMulti
          />
        </div>

        <div className="filter-container-pesquisar2">
          <div className="filter-label-pesquisar2">Veículo</div>
          <AsyncSelect
            className="filter-select-pesquisar2"
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            onInputChange={handleInputChange}
            onChange={handleChange}
            onBlur={handleBlur}
            value={selectedSource}
            styles={customStyles}
            placeholder="Digite para buscar veículo"
            isClearable
          />
        </div>
      </div>

      <div className='filters3'>
        <div className="date-picker-search">
          <div className="date-picker-label-search">Data Inicial</div>
          <div className="date-picker-header-search">
            <div className="date-picker-content-search">
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                dateFormat="dd/MM/yyyy"
                className="selected-date-input"
                placeholderText='DD/MM/AAAA'
                maxDate={endDate}
              />
            </div>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c6cd33072230ae936f1e816d9aa1ba45453ba4d5a4f9099598c1801929589e2?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
              alt=""
              className="calendar-icon-search"
              onClick={() => document.getElementsByClassName('selected-date-input')[0].click()}
            />
          </div>
        </div>

        <div className="date-picker-search">
          <div className="date-picker-label-search">Data Final</div>
          <div className="date-picker-header-search">
            <div className="date-picker-content-search">
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                dateFormat="dd/MM/yyyy"
                className="selected-date-input"
                placeholderText='DD/MM/AAAA'
                minDate={startDate}
              />
            </div>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c6cd33072230ae936f1e816d9aa1ba45453ba4d5a4f9099598c1801929589e2?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
              alt=""
              className="calendar-icon-search"
              onClick={() => document.getElementsByClassName('selected-date-input')[1].click()}
            />
          </div>
        </div>
      </div>

      <div className='filters4'>
        <button className="filter-container-search" onClick={handleShowFilterCategory}>
          Adicionar Filtro de avaliação
        </button>

        <button className='ButtonLimpar' onClick={handleClearFilters}>
          Limpar
        </button>
      </div>

      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}

      <div className="avaliacao-filters"></div>

      <hr className="hr-horizontal-busca"></hr>

      <div className="selected-filters">
        {selectedFilters.map((filter, index) => (
          <div key={index} className="selected-filter-item">
            <span>{filter.name}: {filter.value}</span>
            <button className="remove-filter-button" onClick={() => handleRemoveFilter(index)}>
              <img 
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ad351e4c7a0682391e980d69ebfc6bfce384062db76a83b02eb0f781588ea138?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&" 
                alt="Remove"
                className="remove-filter-icon"
              />
            </button>
          </div>
        ))}
      </div>

      <div className='buttons'>

      <div
          className="selected-items-info"
          id={selectedCards.length > 0 ? "selected-items-visible" : "selected-items-hidden"}
        >
        <span>{`Itens selecionados: ${selectedCards.length}`}</span>
        {selectedCards.length > 0 && (
          <button
            onClick={handleClearSelectedCards}
            className="clear-selection-button"
          >
            Limpar Seleção
          </button>
        )}
      </div>

        <a href={'/'+ clientId + '/telaNoticias'} className='ButtonVoltar'>
          Voltar
        </a>

        <button className='ButtonSearch' onClick={handleButtonClickSearch}>
          Buscar
        </button>
      </div>

      {showFilterCategory && (
        <div className="modal-overlay-busca-avancada">
          <div className="modal-content-busca-avancada">
            <FilterAvaliacao onClose={handleCloseFilterCategory} onAddFilter={handleAddFilter} clientId={clientId} clippingId={[]} loadExistingData={false} />
          </div>
        </div>
      )}

    {filteredData.length > 0 && (
      <>
        <section className="container-selecao" ref={resultsContainerRef}>
          <div className="select-options-wrapper">
            {['Seleção manual', 'Selecionar todos'].map((option, index) => (
              <div key={index} className="option">
                <input
                  type="radio"
                  id={`option-${index}`}
                  name="selectionOption"
                  value={option}
                  checked={selectedOption === option}
                  onChange={handleOptionChange}
                  className="custom-radio"
                />
                <label htmlFor={`option-${index}`} className="custom-radio-label">
                  <span className="custom-checkbox"></span>
                  <span className="text">{option}</span>
                </label>
              </div>
            ))}
          </div>

          <div className="pagination-info">
            {`Apresentando ${totalItems} ${totalItems === 1 ? 'Notícia' : 'Notícias'}`}
          </div>

          <div className="pagination-wrapper">
            <button
              className="pagination-button2"
              onClick={() => handleSearch(1)}
              disabled={currentPage === 1}
            >
              Primeira Página
            </button>
            <button
              className="pagination-button2"
              onClick={() => handleSearch(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </button>
            {renderPageNumbers()}
            <button
              className="pagination-button2"
              onClick={() => handleSearch(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Próxima
            </button>
            <button
              className="pagination-button2"
              onClick={() => handleSearch(totalPages)}
              disabled={currentPage === totalPages}
            >
              Última Página
            </button>
          </div>

          <div className="export-button-wrapper-selecao">
            <button
              className="export-button-selecao"
              tabIndex="0"
              onClick={() => handleButtonClick('exportar')}
            >
              Exportar
            </button>
          </div>
          {userProfile !== 'cliente' && (
              <>
              <div className="actions-wrapper">
                <button
                  className="evaluate-button-selecao"
                  onClick={handleOpenActionsMenu}
                >
                  Ações
                </button>
                {isActionsMenuOpen && (
                  <ActionsModal
                    onEvaluateAll={() => handleButtonClick('avaliar')}
                    onDeleteEvaluations={handleDeleteEvaluations}
                  />
                )}
              </div>
            </>
          )}
        </section>

        <div className='Resultado-Busca-cards custom-card-size'>
        {filteredData.map((item, index) => (
          <div key={item.id} className="card-container">
            <div className='card-checkbox'>
              <input
                type="checkbox"
                id={`card-${item.id}`}
                checked={selectedCards.includes(item.id)}
                onChange={() => handleCardSelectChange(item.id)}
                className='custom-card-checkbox'
              />
              <label htmlFor={`card-${item.id}`} className='custom-card-checkbox-label'></label>
            </div>
            <Card
              clippingId={item.id}
              imageUrl={getImageUrl(item.source_detail.source_type)}
              title={item.title}
              type={item.source_detail.source_type}
              publicationDate={item.publish_date}
              sourceName={item.source_detail.name}
              category={item.categories}
              link={item.link}
              text={item.text}
              city={item.source_detail.city}
              state={item.source_detail.city}
              source={item.source_detail.name}
              hasEvaluate={item.has_evaluation}
              clippings={filteredData} // Certifique-se de passar `filteredData` aqui
              onClick={() => handleCardClick(item.id, index)}
            />
          </div>
        ))}
        </div>
      </>
    )}

      {exportSuccessMessage && <div className="success-message">{exportSuccessMessage}</div>}
      {exportErrorMessage && <div className="error-message">{exportErrorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {warningMessage && <div className="error-message">{warningMessage}</div>}
      {pdfExportSuccessMessage && <div className="success-message">{pdfExportSuccessMessage}</div>}
      {pdfExportErrorMessage && <div className="error-message">{pdfExportErrorMessage}</div>}

      {showAvaliacao && (
        <div className="modal-overlay-resultado-busca" onClick={handleOverlayClick}>
          <div className="modal-content-resultado-busca">
            <Avaliacao
              clientId={clientId}
              clippingId={selectedCards}
              onClose={handleCloseAvaliacao}
              onAddFilter={handleAvaliar}
              loadExistingData={false}
              onSuccessMessage={handleSuccessMessage}
              onErrorMessage={handleErrorMessage}
            />
          </div>
        </div>
      )}

      <NewsPDFExporter ref={pdfExporterRef} clientId={clientId}/>

      {showExportModal && (
        <ExportModal
          onClose={() => setShowExportModal(false)}
          onExportExcel={handleExportClippings}
          onExportPDF={handleExportPDF}
          selectedCards={selectedCards}
        />
      )}
    </div>
  );
}

export default BuscaAvancada;
