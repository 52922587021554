import React from 'react';
import './ActionsModal.css';

const ActionsModal = ({ onEvaluateAll, onDeleteEvaluations }) => {
  return (
    <div className="actions-menu-modal">
      <button onClick={onEvaluateAll}>Avaliar Todas</button>
      <button onClick={onDeleteEvaluations}>Excluir Avaliação</button>
    </div>
  );
};

export default ActionsModal;
